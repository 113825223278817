'use strict';

angular.module('cpformplastApp')
  .config(function($stateProvider) {
    $stateProvider
      .state('settings', {
        url: '/settings',
        templateUrl: 'app/settings/settings.html',
        controller: 'SettingsController',
        controllerAs: 'vm',
        authenticate: ['admin','management_dept','logistic_dept','shipper','receptionist','productionManagerIn','productionManagerOut']
      });
  });
